@import "animate.css";
@import "photoswipe/dist/photoswipe.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: "Inconsolata";
  src: url("../fonts/Inconsolata-Regular.ttf") format("truetype");
}

/* * {
  box-sizing: border-box;
} */

body,
button,
input,
textarea,
select {
  -webkit-font-smoothing: antialiased;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  min-height: 100vh;
  @apply font-sans;
}

#root {
  min-height: 100vh;
}

/* markdown */
.some-markdown {
  color: #111;
}

.some-markdown code {
  padding: 1px 3px;
  border-radius: 2px;
  background-color: #f4f4f4;
  font-family: Inconsolata, monospace;
}

.some-markdown p,
.some-markdown ul,
.some-markdown ol,
.some-markdown pre,
.some-markdown blockquote {
  margin-top: 12px;
  margin-bottom: 12px;
}

.some-markdown a {
  text-decoration: underline;
}

.some-markdown ul,
.some-markdown ol {
  list-style: initial;
  margin-left: 18px;
}

.some-markdown li {
  margin-top: 2px;
  margin-bottom: 2px;
}

.some-markdown pre {
  color: #24292e;
  background: #f4f4f4;
  border-radius: 2px;
}

.some-markdown pre code {
  display: block;
  overflow-x: auto;
  padding: 6px 9px;
}

.some-markdown hr {
  background-color: #ddd;
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  height: 1px;
}

.some-markdown > :last-child {
  margin-bottom: 0;
}

/* hljs */
/*!
Theme: GitHub
Description: Light theme as seen on github.com
Author: github.com
Maintainer: @Hirse
Updated: 2021-05-15

Outdated base version: https://github.com/primer/github-syntax-light
Current colors taken from GitHub's CSS
*/
.hljs-doctag,
.hljs-keyword,
.hljs-meta .hljs-keyword,
.hljs-template-tag,
.hljs-template-variable,
.hljs-type,
.hljs-variable.language_ {
  color: #d73a49;
}
.hljs-title,
.hljs-title.class_,
.hljs-title.class_.inherited__,
.hljs-title.function_ {
  color: #6f42c1;
}
.hljs-attr,
.hljs-attribute,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-operator,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-id,
.hljs-variable {
  color: #005cc5;
}
.hljs-meta .hljs-string,
.hljs-regexp,
.hljs-string {
  color: #032f62;
}
.hljs-built_in,
.hljs-symbol {
  color: #e36209;
}
.hljs-code,
.hljs-comment,
.hljs-formula {
  color: #6a737d;
}
.hljs-name,
.hljs-quote,
.hljs-selector-pseudo,
.hljs-selector-tag {
  color: #22863a;
}
.hljs-subst {
  color: #24292e;
}
.hljs-section {
  color: #005cc5;
  font-weight: 700;
}
.hljs-bullet {
  color: #735c0f;
}
.hljs-emphasis {
  color: #24292e;
  font-style: italic;
}
.hljs-strong {
  color: #24292e;
  font-weight: 700;
}
.hljs-addition {
  color: #22863a;
  background-color: #f0fff4;
}
.hljs-deletion {
  color: #b31d28;
  background-color: #ffeef0;
}
